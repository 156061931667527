/* stylelint-disable declaration-no-important */

/* Headlines */
h1,
.h1 {
    font-family: var(--theme-headlines) !important;

    h2 {
        font-family: var(--theme-subheadlines) !important;
        font-weight: var(--fw-medium) !important;
    }
}

h2,
.h2 {
    font-family: var(--theme-headlines) !important;

    h3 {
        font-family: var(--theme-subheadlines) !important;
        font-weight: var(--fw-medium) !important;
    }
}

.title-h1,
.title-h2 {
    font-family: var(--theme-headlines) !important;

    h2,
    h3 {
        font-family: var(--theme-subheadlines) !important;
        font-weight: var(--fw-medium) !important;
    }
}

.h1-sub,
.title-h1-sub,
.title-h2-sub {
    font-family: var(--theme-subheadlines) !important;
    font-weight: var(--fw-medium) !important;
}

.product-details-header__subtitle {
    h2 {
        font-family: var(--theme-subheadlines) !important;
        font-weight: var(--fw-medium) !important;
    }
}
