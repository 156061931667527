.main-navigation-vertical-brand__logo-container {
    img {
        min-width: 150px;
        height: auto;

        @include mq($from: desktop) {
            min-width: 190px;
        }
    }
}

.main-navigation-vertical-brand__burger-menu {
    svg {
        fill: var(--color-icons);
    }
}

.main-navigation-vertical-brand__icon-wrapper {
    .icon {
        color: var(--color-icons);
        fill: var(--color-icons);
        transform: scale(1);
    }
}

.main-navigation-vertical-brand__icon {
    fill: var(--color-icons);
    transform: scale(1) !important; /* stylelint-disable-line declaration-no-important */
}
