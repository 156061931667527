// Custom Properties in Proactiv
:root {
    // Colors
    --theme-primary: #00afc1;
    --theme-secondary: #f5f1ea;
    --theme-tertiary: #e8f4f5;
    --theme-on-default: #222;

    --theme-primary-transparent: #756bea;
    --theme-primary-opaque: rgb(94, 177, 50, 0.05);

    --theme-attention: var(--theme-primary);
    --theme-promotion: var(--theme-primary);
    --theme-cta: var(--theme-primary);
    --theme-cta-font-color: #fff;

    // Fonts
    --ff-base: 'GTWalsheim', sans-serif;
    --ff-menu: 'GTWalsheim', sans-serif;
    --ff-headline: 'GTWalsheim', sans-serif;
    --font-base: 'GTWalsheim', sans-serif;
    --font-headlines: 'GTWalsheim', sans-serif;
    --font-product-price: 'GTWalsheim', sans-serif;

    --theme-headlines: 'GTWalsheim', sans-serif;
    --theme-subheadlines: 'GTWalsheim', sans-serif;

    --menu-color-primary: var(--theme-on-default);
    --color-headline: var(--theme-on-default);
    --color-subheadline: var(--theme-on-default);
    --color-black: var(--theme-on-default);
    --color-gray-800: var(--theme-on-default);

    --fs-main-navigation-mobile: 20px;

    // Headlines Font Sizes
    --fs-h1: 32px;
    --fs-h1-mobile: 25px;
    --fs-h1-sub: calc(var(--fs-h1) * 0.65);
    --fs-h1-sub-mobile: calc(var(--fs-h1-mobile) * 0.65);

    --fs-h2: 28px;
    --fs-h2-mobile: 21px;
    --fs-h2-sub: calc(var(--fs-h2) * 0.65);
    --fs-h2-sub-mobile: calc(var(--fs-h2-mobile) * 0.65);

    --fs-h3: 25px;
    --fs-h3-mobile: 19px;
    --fs-h3-sub: calc(var(--fs-h3) * 0.65);
    --fs-h3-sub-mobile: calc(var(--fs-h3-mobile) * 0.65);

    --fs-h4: 20px;
    --fs-h4-mobile: 17px;
    --fs-h4-sub: calc(var(--fs-h4) * 0.65);
    --fs-h4-sub-mobile: calc(var(--fs-h4-mobile) * 0.65);

    --fs-h5: 18px;
    --fs-h5-mobile: 16px;
    --fs-h5-sub: calc(var(--fs-h5) * 0.65);
    --fs-h5-sub-mobile: calc(var(--fs-h5-mobile) * 0.65);

    --fs-h6: 14px;
    --fs-h6-mobile: 13px;

    // MainNavigationVB
    --color-background: #f5f1ea;
    --color-icons: #003a38;
    --color-main-navigation-vb-hover: #756bea;

    // Mobile Menu
    --mobile-menu-top: 110px;

    // ProductGridElement
    --background-product-buy-button: #756bea;
    --color-product-title: #2d2f2d;

    // PDP
    --pdp-background-buy-button: #756bea;
    --pdp-color-product-title: #2d2f2d;

    // Main Navigation Custom
    --main-navigation-custom-dropdown-background-color: var(--theme-secondary);

    // Burger Menu
    --burger-menu-icon-color: #003a38;

    // Desktop Navigation
    --desktop-navigation-font-weight: var(--fw-normal);
}
