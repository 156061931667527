@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Ultra-Light.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 100 100;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Thin.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 200 200;
}
@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Light.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 300 300;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Regular.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 400 400;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Medium.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 500 500;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Bold.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 600 600;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Black.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 700 700;
}

@font-face {
    font-family: 'GTWalsheim';
    src: url('../assets/fonts/GTWalsheim/GT-Walsheim-Ultra-Bold.otf') format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 800 1000;
}
